// Bootstrap
@import url("../template/vendors/bootstrap/css/bootstrap.min.css");

// Bootstrap select
@import url("../template/vendors/bootstrap-select/dist/css/bootstrap-select.min.css");

// Select2
@import url("../template/vendors/select2/dist/css/select2.min.css");

// Bootstrap 3 Date/Time Picker
@import url("../template/vendors/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css");

// DataTables
@import url("../template/vendors/datatables/media/css/dataTables.bootstrap4.css");

// Icons
@import url("../template/vendors/font-icomoon/style.css");

/* CLEAN UI ADMIN TEMPLATE MODULES */
/* v2.0.0 */
@import url("../template/modules/core/common/core.cleanui.css");
@import url("../template/modules/vendors/common/vendors.cleanui.css");
@import url("../template/modules/menu-left/common/menu-left.cleanui.css");
@import url("../template/modules/top-bar/common/top-bar.cleanui.css");
@import url("../template/modules/footer/common/footer.cleanui.css");
@import url("../template/modules/pages/common/pages.cleanui.css");
@import "repairs";