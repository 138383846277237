/* CLEAN UI ADMIN TEMPLATE REPAIRS */

button:hover {
  cursor: pointer;
}

.select2-hidden {
  display: none !important;
}

.control-button {
  padding: 10px !important;
  width: 100%;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

tr.danger {
  background-color: #fb434a;
  color: white;
}

.modal-content {
  img {
    width: 100%;
    height: auto;
  }
}

.visit-info {
  margin-bottom: 20px;
  border-bottom: 1px solid #e9ecef;
}

#visit-modal {
  .info .row {
    border-bottom: 1px solid #e9ecef;

    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 1rem;
    }
  }
}

#check-modal {
  .form-group {
    height: 90%;
    textarea {
      height: 98%;
    }
  }
}

#default {
  .heading {
    h3 {
      margin-top: 10px;
      border-bottom: 1px solid #e4e9f0;
    }
  }
  .menu {

    > div {

      padding: 0.71rem;
      height: 270px;

      a {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        flex-flow: column;

        height: 100%;
        width: 100%;

        font-size: 26px;
        background-color: #0190fe;
        color: white;

        transition: box-shadow 0.1s ease-in-out;

        small {
          font-size: 16px;
        }

        &:hover {
          -moz-box-shadow:    0 0 10px #000000;
          -webkit-box-shadow: 0 0 10px #000000;
          box-shadow:         0 0 10px #000000;
          transition: box-shadow 0.1s ease-in-out;
        }
      }
    }
  }
}

.login-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/build/images/app/login_bg_2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Tables */

table {

  tbody > tr > td {
    vertical-align: middle;
  }

  .controls {
    width: 1%;
    white-space: nowrap;
  }
}

.rotate {
  display: inline-block;
  padding: 3px 0;
  -webkit-animation: rotation 1s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}